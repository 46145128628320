<template>
  <v-container>
    <v-card>
      <v-container>
        <v-divider />
        <v-col />
        <v-form>
          <v-row>
            <v-col cols="2" class="pt-4">
              <span>หัวข้อ</span><span style="color:red;">*</span>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="title"
                placeholder="หัวข้อ"
                outlined
                dense
                @keyup.enter="submit()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-4">
              <span>รายละเอียด</span><span style="color:red;">*</span>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-model="detail"
                placeholder="รายละเอียด"
                outlined
                dense
                @keyup.enter="submit()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-8">
              <span>อัพโหลดรูปภาพ</span>
            </v-col>
            <v-col cols="4" class="pt-4">
              <v-text-field
                v-model="picture_2"
                outlined
                dense
                @click="changePic2()"
                :placeholder="imageName2"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure2"
                accept="image/*"
                @change="showPicture2"
                style="display: none"
            /></v-col>
            <v-col cols="2" class="mt-1">
              <v-btn v-if="showImage == ''" disabled>อัพโหลด</v-btn>
              <v-btn v-else @click="upload2()">อัพโหลด</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"></v-col>
            <v-img :src="showImage" max-height="250" max-width="250"></v-img
          ></v-row>
          <v-row class="pa-5">
            <v-btn color="error" @click="cancel()">ยกเลิก</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#744D9c" style="color:white;" @click="submit()">บันทึก</v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import { Decode } from '@/services'
export default {
  data() {
    return {
      search: "",
      title: "",
      detail: "",
      picture_2: "",
      imageName2: "คลิกเพื่ออัพโหลดรูปภาพ",
      imageBase2: "",
      showImage: "",
    };
  },
  methods: {
    async cancel() {
      await this.$swal.fire({
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: "success",
        text: "กลับสู่หน้าแสดงข้อมูลจัดการข่าว",
      });
      (this.title = ""),
        (this.detail = ""),
        (this.showImage = "showImage"),
        this.$router.push("/showallnews");
    },
    async submit() {
        if (this.title != "") {
          if (this.detail != "") {
            const data = {
              title: this.title,
              detail: this.detail,
              imgUrl: this.picture_2,
            };
            var checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
            const auth = {
              headers: { Authorization: `Bearer` + " " + checkuser.token },
            };
            const response = await axios.post(
              `${process.env.VUE_APP_API}/news`,
              data,
              auth
            );
            console.log("logadd", response.data.data);
            const swal = await this.$swal.fire({
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              icon: "success",
              title: "เพิ่มข้อมูลสำเร็จ",
            });
            await this.$router.push("/showallnews");
        } else {
          const swal = await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            title: "กรุณากรอกรายละเอียด",
          });
          this.title = "";
        }
      } else {
        const swal = await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          title: "กรุณากรอกหัวข้อ",
        });
        this.detail = "";
      }
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase2 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    async upload2() {
      const img2 = {
        imgs: this.imageBase2,
      };
      const response = await axios.post(
        `${process.env.VUE_APP_API}/attachImgs`,
        img2
      );
      this.picture_2 = response.data.data.imgUrl;
      console.log("picture_2", response.data.data);
      this.$swal.fire({
        icon: "success",
        text: "อัพโหลดสำเร็จ",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
      return response;
    },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
